<!--
 * @Author: jarvis
 * @Date: 2023-05-02 09:29:21
 * @LastEditors: jarvis
 * @LastEditTime: 2023-05-09 15:35:12
 * @Description: 
 * @FilePath: \ydy-pc 备份\src\views\home\staffPresence.vue
-->
<template>
  <!-- couldManage -->
  <div>
    <myheader></myheader>
    <div id="gjfx" class="content-Box3">
      <!-- 工坊方向内容名称-->
      <!-- <div class="title1 wow animate__fadeIn">引言</div> -->
      <div class="fengcai">
        <!-- <img src="../../../src/assets/book.png" alt=""> -->
        <div class="content">江苏一道云科技发展有限公司是2022年全国职业院校技能大赛赛项合作企业，也是首届世界职业院校技能大赛赛项合作企业。近年来，公司聚九州人脉,纳四海英才，众多云计算大赛选手进入一道云科技工作，在实践中成长，在历练中提升，为公司创造价值。</div>
        
        
        </div>
      <div class="gamePic">
        <img src="../../../src/assets/games/game1.jpg" alt="">
        <img src="../../../src/assets/games/game2.jpg" alt="">
        <img src="../../../src/assets/games/game3.jpg" alt="">
        <img src="../../../src/assets/games/game4.jpg" alt="" >
      </div>
      <div class="title1 wow animate__fadeIn">员工风采</div>
      <div class="containerMain">
        <div class="contentMain">
          <div class="teaching-Case">
            <div class="teaching-Title2">
              <div class="title">{{ firstPeople.title }}</div>
              <div class="line"></div>
              <div class="time">{{ firstPeople.time }}</div>
            </div>
            <div class="teaching-Title3">
              <ul>
                <li v-for="(item, index) in firstPeople.content" :key="'content-' + index">{{ item }}</li>
              </ul>
            </div>
          </div>
          <!--方案概述的图片 -->
          <img :src="firstPeople.url" alt="" class="imgs"/>
        </div>
        <div id="peyms" class="content-Box wow animate__fadeInUp">
          <!-- 培养模式的名字-->
          <div class="title1 wow animate__fadeInUp">
            {{ firstPeople.content1 }}
          </div>
          <!-- 培养模式的图片1-->
          <div class="jieshao">{{ firstPeople.jieshao }}</div>
          <div class="commonlyGame">
            <div class="commonly1">
              <img :src="firstPeople.pics.url4" alt="" />
            </div>
            <div class="commonly2">
              <img :src="firstPeople.pics.url2" alt="" />
              <img :src="firstPeople.pics.url3" alt="" class="commonlyGamePic"/>
            </div>
            
          </div>
        </div>
        <div id="peyms" class="content-Box wow animate__fadeInUp">
          <!-- 培养模式的名字-->
          <div class="title1 wow animate__fadeInUp">
            {{ firstPeople.content2 }}
          </div>
          <!-- 培养模式的图片1-->
          <div class="jieshao2 mt">{{ firstPeople.jieshao2 }}</div>
          <div class="jieshao2">{{ firstPeople.jieshao3 }}</div>
          <div class="commonly">
            <img src="../../../src/assets/guo/4.png" alt="" />
            <img src="../../../src/assets/guo/5.png" alt="" />
            <img src="../../../src/assets/guo/6.png" alt="" />
            <img src="../../../src/assets/guo/7.png" alt="" />
            <img src="../../../src/assets/guo/8.png" alt="" />
            <img src="../../../src/assets/guo/9.png" alt="" />
            <img src="../../../src/assets/guo/10.png" alt="" />
            <img src="../../../src/assets/guo/11.png" alt="" />
          </div>
        </div>
      </div>
      <!--for循环 工坊方向的几个盒子+方向详细内容 -->
      <div class="workshop-Direction" style="margin-bottom: 20px">
        <div class="teaching-Case1 wow animate__fadeInUp" v-for="(teaching_online, index) in teachings_online" :key="'teaching_online-' + index">
          <div class="teaching-img">
            <img :src="teaching_online.url" />
          </div>
          <div class="teaching-Title2">
            <div class="title">{{ teaching_online.title }}</div>
            <div class="line"></div>
            <div class="time">{{ teaching_online.time }}</div>
          </div>
          <div class="teaching-Title3">
            <ul>
              <li v-for="(item, index) in teaching_online.content" :key="'content-' + index">{{ item }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div id="gjfx" class="content-Box3">
      <!-- 工坊方向内容名称-->
      <div class="title1 wow animate__fadeIn"></div>
      <!--for循环 工坊方向的几个盒子+方向详细内容 -->
      <div class="workshop-Direction" style="margin-bottom: 20px">
        <div class="teaching-Case1 wow animate__fadeInUp" v-for="(teaching, index) in teachings" :key="'teachingA-' + index">
          <div class="teaching-img">
            <img :src="teaching.url" />
          </div>
          <div class="teaching-Title2">
            <div class="title">{{ teaching.title }}</div>
            <div class="line"></div>
            <div class="time">{{ teaching.time }}</div>
          </div>
          <div class="teaching-Title3">
            <ul>
              <li v-for="(item, index) in teaching.content" :key="'content-' + index">{{ item }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <mybottom></mybottom>
  </div>
</template>

<script>
import myheader from '@/components/Header.vue';
import mybottom from '@/components/Bottom.vue';
export default {
  components: { myheader, mybottom },
  data() {
    return {
      firstPeople: {
        url: require('../../../src/assets/games/guo.jpg'),
        title: '郭常贺',
        time: '2022.8至今',
        content: ['河南经贸职业学院', '2021年河南省高等职业教育技能大赛云计算赛项一等奖', '2021年全国职业院校技能大赛云计算赛项一等奖第一名', '2022年首届世界职业院校技能大赛云计算赛项金奖'],
        content1: '个人荣誉',
        jieshao: '河南省高校“大学生党员标兵”',
        pics: {
          url2: require('../../../src/assets/guo/2.png'),
          url3: require('../../../src/assets/guo/3.jpg'),
          url4: require('../../../src/assets/guo/1.jpg'),
        },
        content2: '技能证书',
        jieshao2: `2021年，郭常贺同学顺利通过RHCA认证（红帽认证架构师Red Hat Certified Architect），共包含五项证书：RHCA-性能调优证书、RHCA-高级自动化Ansible证书、RHCA-分布式存储Ceph证书、RHCA-OpenShift容器管理证书、RHCA-OpenStack证书、RHCA-高级架构师证书。`,
        jieshao3: `据悉，红帽认证架构师是红帽公司继红帽认证技师（RHCT）和红帽认证工程师（RHCE）认证之后推出的最新顶级认证，以实际操作能力为基础的测试项目，主要考察考生在实际操作系统中的实践能力。截止2021年，国内仅有800多人拥有，全球不到一万人拥有。`,
      },
      teachings_online: [
        {
          url: require('../../../src/assets/games/fan.jpg'),
          title: '樊闯',
          time: '2018.7至今',
          content: ['2018年毕业于无锡商业职业技术学院', '2018年江苏省高职技能大赛“云计算技术与应用”赛项一等奖', '2018年全国职业院校技能大赛云计算技术与应用赛项二等奖'],
        },
        
        // {
        //   url: require('../../../src/assets/chilun.png'),
        //   title: '彭添淞',
        //   time: '2022.11至今',
        //   content: ['就读于深圳职业技术学院', '2021-2022年度广东省职业院校专业技能大赛（高职组）云计算赛项一等奖', '2022年全国职业院校技能大赛高职组云计算赛项一等奖'],
        // },
        {
          url: require('../../../src/assets/games/zheng.jpg'),
          title: '郑威迪',
          time: '2018.8至今',
          content: ['2018年毕业于珠海城市职业技术学院', '2018年全国职业院校技能大赛云计算技术与应用赛项一等奖'],
        },
        {
          url: require('../../../src/assets/games/cai.jpg'),
          title: '蔡晨亮',
          time: '2023.4至今',
          content: [
            '2020年毕业于安徽工业经济职业技术学院',
            '2022年毕业于合肥师范学院',
            '2019年安徽省职业院校技能大赛云计算技术与应用赛项一等奖',
            '2019年全国职业院校技能大赛云计算技术与应用赛项一等奖',
          ],
        },
        {
          url: require('../../../src/assets/games/liu.jpg'),
          title: '刘卓承',
          time: '2021.7至今',
          content: ['2019年毕业于重庆工商职业学院 ', '2021年毕业于重庆理工大学', '2018年全国职业院校技能大赛云计算技术与应用赛项二等奖', '2019年全国职业院校技能大赛云计算技术与应用赛项一等奖'],
        },
      ],
      teachings: [
        {
          url: require('../../../src/assets/chilun.png'),
          title: '程然',
          time: '2015~2018年',
          content: ['安徽商贸职业技术学院', '2014年全国职业院校技能大赛云安全技术赛项一等奖'],
        },
        {
          url: require('../../../src/assets/chilun.png'),
          title: '孙斌',
          time: '2015~2017',
          content: ['南京信息职业技术学院', '2015年全国职业院校技能大赛云计算技术应用一等奖'],
        },
        {
          url: require('../../../src/assets/chilun.png'),
          title: '陶中元',
          time: '2015~2017',
          content: ['南京信息职业技术学院', '2015年全国职业院校技能大赛云计算技术应用一等奖'],
        },
        {
          url: require('../../../src/assets/chilun.png'),
          title: '张玉婷',
          time: '2015~2019',
          content: ['无锡科技职业技术学院', '2015年全国职业院校技能大赛云计算技术应用二等奖第一名'],
        },
        {
          url: require('../../../src/assets/chilun.png'),
          title: '顾明',
          time: '2018~2019',
          content: ['无锡商业职业技术学院', '2016年全国职业院校技能大赛云计算技术应用一等奖'],
        },
        {
          url: require('../../../src/assets/chilun.png'),
          title: '陈文亮',
          time: '2017~2020',
          content: ['无锡商业职业技术学院', '2016年全国职业院校技能大赛云计算技术应用一等奖'],
        },
        // {
        //   url: require('../../../src/assets/chilun.png'),
        //   title: '苏喜硕',
        //   time: '前实习生',
        //   content: ['山东商业职业技术学院 山东商业职业技术学院', '2020年全国职业院校技能大赛云计算赛项一等奖第一名'],
        // },
      ],
    };
  },
  created() {},
  mounted() {
    var wow = new this.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
    });
    wow.init();
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.content-box3 {
  width: 1200px;
  height: 410px;
  background-color: #ffffff;
  /* box-shadow: 0px 0px 3px 3px #e4dcdc; */
  margin-top: 20px;
  /* display: flex; */
}
.title1 {
  margin-left: auto;
  margin-right: auto;
  color: black;
  font-weight: 600;
  font-size: 30px;
  padding-top: 65px;
  text-align: center;
}
.teaching-Icon3 {
  text-align: center;
  margin-top: 30px;
  width: 100%;
  height: 50px;
}
.teaching-img {
  text-align: center;
  margin-top: 30px;
  width: 100px;
}
.teaching-img img{
  width: 100%;
}
.teaching-Title2 {
  text-align: center;
  margin-top: 20px;
  font-weight: 700;
  color: black;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.teaching-Title2 .title {
  font-size: 30px;
}
.teaching-Title2 .line {
  width: 1px;
  height: 20px;
  background: black;
  margin: 0 10px;
}

.teaching-Title3 {
  margin: 20px;
  box-sizing: border-box;
  // line-height: 2;
  font-size: 16px;

}
.workshop-Direction {
  /* margin-top:-10px; */
  display: flex;
  flex-flow: wrap;
  width: 1300px;
  margin: 0 auto;
  /*盒子会自动掉下来*/
}
.teaching-Case1 {
  width: 30%;
  margin-left: 21px;
  margin-top: 30px;
  background-color: blaclk;
  box-shadow: 0px 0px 15px 1px rgb(226 222 222);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.teaching-Case {
  margin-left: 21px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.containerMain {
  width: 900px;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px 3px #e4dcdc;
  margin: 50px auto;
}
.contentMain {
  display: flex;
  justify-content: space-around;
}
.contentMain .imgs {
  width: 200px;
  margin: 30px;
}
.jieshao {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
}
.jieshao2 {
  font-size: 18px;
  padding: 30px 30px 0 30px;
  text-indent: 4ch;
}
.jieshao3 {
  font-size: 18px;
  padding: 0 30px 0 30px;
  text-indent: 4ch;
}
.commonly,.commonlyGame{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.commonlyGame img {
  width: 400px;
  margin: 0px 20px;
}
.commonlyGame .commonlyGamePic{
  margin-top: 20px;
}
.commonlyGame .commonly2{
  display: flex;
  flex-direction: column;
}
.commonly img {
  width: 350px;
  margin: 30px;
}
.content-box1 {
  width: 900px;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px 3px #e4dcdc;
  margin: 50px auto;
  display: flex;
  justify-content: space-around;
}
.fengcai{
  height: 300px;
  margin: 0 auto;
  margin-top: 10px;
  background: url(../../../src/assets/admin_login/background.png) -40px;
  display: flex;
  justify-content: space-around;
  align-items: center;

}
.fengcai img{
  width: 150px;
  height: 150px;
}
.fengcai .content{
  width: 720px;
  font-size: 18px;
  text-indent: 4ch;
  line-height: 50px;
  margin-left: 250px;
}
.gamePic{
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
}
.gamePic img{
  width: 520px;
  height: 260px;
  margin: 20px;
}
</style>

